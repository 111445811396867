import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import { fetchData, fetchSettings } from '../actions';
import {BLOG_CATEGORIES, BLOG_ARTICLES, IMAGES} from "../config";
import '../assets/styles/article.css';
import _ from "lodash";

class ArticleScreen extends Screen {

    renderScreen() {
        const uri = this.getPath().replace('/blog/', '');

        if (!_.has(BLOG_ARTICLES, uri)) {
            window.location = '/blog';
            return null;
        }

        const article = BLOG_ARTICLES[uri];

        const categoriesNames = _.reduce(article.categories, (result, id) => {
            return [ ...result, BLOG_CATEGORIES[id] ];
        }, []);

        return (
            <div className="article">
                <div className="article__content">
                    <Sections links={{
                        '/': __('Domov'),
                        '/blog': __('Blog'),
                        '': article.name,
                    }} />
                    <div className="article__content__data">
                        <div className="article__content__data__title">{article.name}</div>
                        <div className="article__content__data__info">
                            <div className="article__content__data__info__value">{__('Autor')}: {article.author}</div>
                            <div className="article__content__data__info__value">{__('Doba čítania')}: {article.time}</div>
                            <div className="article__content__data__info__value">{_.join(categoriesNames, ' | ')}</div>
                        </div>
                        <div className="article__content__data__text" dangerouslySetInnerHTML={{ __html: article.perex }} />
                        <div className="article__content__data__photo">
                            <img src={IMAGES[article.image]} />
                        </div>
                        <div className="article__content__data__html" dangerouslySetInnerHTML={{ __html: article.text }} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(ArticleScreen));
