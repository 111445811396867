import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Navigate } from '../components';
import { changeSetting } from '../actions';
import { __ } from '../functions';
import '../assets/styles/warning.css';

class Warning extends Navigate {

    render() {
        const { data } = this.props;

        if (_.isEmpty(data)) {
            // Nie su nacitane data
            return null;
        }

        return (
            <div className="warning">
                {__('Máme voľné už iba 2 termíny dodania na tento rok!')}
            </div>
        );
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { changeSetting })(Warning));
