import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import DoneIcon from '@mui/icons-material/Done';
import { __ } from '../functions';
import { fetchData, fetchSettings } from '../actions';
import { IMAGES } from '../config';
import '../assets/styles/care.css';

class CareScreen extends Screen {

    renderScreen() {
        return (
            <div className="care">
                <div className="care__hero">
                    <img src={IMAGES['hero-transparentnost.webp']} alt="hero transparentnost" />
                    <div className="care__hero__content">
                        <Sections links={{
                            '/': __('Domov'),
                            '': __('Transparentnosť'),
                        }} />
                        <div className="care__hero__content__data">
                            <div className="care__hero__content__data__title">{__('Transparentnosť')}</div>
                            <div className="care__hero__content__data__text" dangerouslySetInnerHTML={{ __html: __('Dáme vám k dispozícii fotografie počas výstavby domu, stavebný denník prác a faktúry nakúpeného tovaru.') }} />
                        </div>
                    </div>
                </div>
                <div className="care__third">
                    <div className="care__third__content">
                        <div className="care__third__content__left">
                            <div className="care__third__content__left__section">{__('Transparentnosť')}</div>
                            <div className="care__third__content__left__title">{__('Nemáme čo skrývať')}</div>
                            <div className="care__third__content__left__text" dangerouslySetInnerHTML={{ __html: __('Čo všetko je pre vás k nahliadnutiu?') }} />
                            <div className="care__third__content__left__lines">
                                <div className="care__third__content__left__lines__line">
                                    <DoneIcon />
                                    <div className="care__third__content__left__lines__line__text" dangerouslySetInnerHTML={{ __html: __('Fotky zo všetkych krokov výstavby') }} />
                                </div>
                                <div className="care__third__content__left__lines__line">
                                    <DoneIcon />
                                    <div className="care__third__content__left__lines__line__text" dangerouslySetInnerHTML={{ __html: __('Stavebný denník vedený počas výstavby') }} />
                                </div>
                                <div className="care__third__content__left__lines__line">
                                    <DoneIcon />
                                    <div className="care__third__content__left__lines__line__text" dangerouslySetInnerHTML={{ __html: __('Zoznam použitých materiálov') }} />
                                </div>
                            </div>
                            <div className="care__third__content__left__info">
                                <div className="care__third__content__left__info__name">{__('Chcete vidieť fotky počas výstavby vzorového domu?')}</div>
                                <div className="care__third__content__left__info__text" dangerouslySetInnerHTML={{ __html: __('Nech sa páči, všetky nájdete na tejto <a href="https://www.dropbox.com/sh/76t2les59be6ab5/AACfH-K__jZKnZJKiXLCvMK5a?dl=0" target="_blank">stránke</a>.') }} />
                            </div>
                        </div>
                        <div className="care__third__content__photo">
                            <img src={IMAGES['transparentnost-1.webp']} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(CareScreen));
