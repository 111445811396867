import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { ButtonBase } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import { fetchData, fetchSettings } from '../actions';
import { IMAGES } from '../config';
import '../assets/styles/custom.css';

class CustomScreen extends Screen {

    renderScreen() {
        return (
            <div className="custom">
                <div className="custom__hero">
                    <img src={IMAGES['hero-vyuzitie.webp']} alt="hero vyuzitie" />
                    <div className="custom__hero__content">
                        <Sections links={{
                            '/': __('Domov'),
                            '': __('Využitie našich domov'),
                        }} />
                        <div className="custom__hero__content__data">
                            <div className="custom__hero__content__data__title">{__('Využitie našich domov')}</div>
                            <div className="custom__hero__content__data__text" dangerouslySetInnerHTML={{ __html: __('Spísali sme pre vás zopár príkladov ako viete využiť dom od nás.') }} />
                            <div className="custom__hero__content__data__button">
                                <ButtonBase onClick={() => this.redirect('/elis')}>{__('Model Elis 25m2')}</ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom__process">
                    <div className="custom__process__content">
                        <div className="custom__process__content__section">{__('Využitie')}</div>
                        <div className="custom__process__content__title">{__('V akých prípadoch viete využiť domy od nás?')}</div>
                        <div className="custom__process__content__text" dangerouslySetInnerHTML={{ __html: __('Samozrejme ide len o pár príkladov, každý z vás má svoje potreby a požiadavky.') }} />
                        <div className="custom__process__content__panels">
                            <div className="custom__process__content__panels__panel">
                                <div className="custom__process__content__panels__panel__number">01</div>
                                <div className="custom__process__content__panels__panel__title">{__('Celoročné bývanie pre 2 osoby')}</div>
                                <div className="custom__process__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Či už ste mladý pár ktorí býva u rodičov a potrebuje svoje súkromie alebo ste dôchodcovia ktorím stačí niečo menšie.') }} />
                            </div>
                            <div className="custom__process__content__panels__panel">
                                <div className="custom__process__content__panels__panel__number">02</div>
                                <div className="custom__process__content__panels__panel__title">{__('Hosťovský dom')}</div>
                                <div className="custom__process__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Navštevuje vás často rodina a potrebujete svoje súkromie? Dom od nás viete využiť na svojom pozemku ako hosťovský dom pre návštevy.') }} />
                            </div>
                            <div className="custom__process__content__panels__panel">
                                <div className="custom__process__content__panels__panel__number">03</div>
                                <div className="custom__process__content__panels__panel__title">{__('Rekreačný dom')}</div>
                                <div className="custom__process__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Máte rekreačný pozemok alebo záhradu kde chcete tráviť svoje volné dní? Ak áno tak dom od nás viete využiť na vašej záhrade.') }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(CustomScreen));
