import React, { Component } from 'react';
import _ from 'lodash';
import { Snackbar } from '@mui/material';
import { Message } from './Message';
import { getUrlPath } from '../functions';

class Navigate extends Component {

    redirect(path) {
        this.props.navigate(getUrlPath(path));
    }

    isMobile() {
        return window.innerWidth < 1200;
    }

    getPath() {
        return this.props.location.pathname;
    }

    showSnackbar(type, message) {
        this.setState({ snackbar: { ...this.state.snackbar, ...{ type, message } } });
    }

    onCloseSnackbar() {
        this.setState({ snackbar: { ...this.state.snackbar, type: null } });
    }

    renderSnackbar() {
        if (!_.has(this.state, 'snackbar')) {
            return null;
        }

        const { type, message } = this.state.snackbar;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={!_.isEmpty(type)}
                autoHideDuration={6000}
                onClose={this.onCloseSnackbar.bind(this)}
                style={{ zIndex: 5000000 }}
                message={<Message type={type}>{message}</Message>}
            />
        );
    }
}

export { Navigate };
