import _ from 'lodash';

export const formatPhone = phone => {
    phone = phone.replace(/ /g, '');

    let delimiter = 3;

    if (phone.indexOf('+') === 0) {
        delimiter = 4;
    }

    let phoneData = [];
    let phonePart = '';

    for (let i = 0; i < phone.length; i++) {
        phonePart = `${phonePart}${phone[i]}`

        if (phonePart.length === delimiter) {
            delimiter = 3;
            phoneData = [ ...phoneData, phonePart ];
            phonePart = '';
        }
    }

    if (phonePart !== '') {
        phoneData = [ ...phoneData, phonePart ];
    }

    return _.join(phoneData, ' ');
};
