import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import {__, formatAmount, toNumber} from '../functions';
import { fetchData, fetchSettings } from '../actions';
import {BLOG_ARTICLES, IMAGES, ELIS_PRICE} from '../config';
import '../assets/styles/elis.css';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import {ButtonBase} from "@mui/material";
import {Slideshow} from "../components/Slideshow";
import _ from "lodash";

class ElisScreen extends Screen {
    state = {
        photo: 0,
        photoReal: 0,
    };

    onPrev() {
        let { photo } = this.state;

        photo -= 1;

        if (photo < 1) {
            photo = 10;
        }

        this.setState({ photo });
    }

    onNext() {
        let { photo } = this.state;

        photo += 1;

        if (photo > 10) {
            photo = 1;
        }

        this.setState({ photo });
    }

    onPrevReal() {
        let { photoReal } = this.state;

        photoReal -= 1;

        if (photoReal < 1) {
            photoReal = 15;
        }

        this.setState({ photoReal });
    }

    onNextReal() {
        let { photoReal } = this.state;

        photoReal += 1;

        if (photoReal > 15) {
            photoReal = 1;
        }

        this.setState({ photoReal });
    }

    renderScreen() {
        const { photo, photoReal } = this.state;

        return (
            <div className="choose">
                <Slideshow
                    photo={photo}
                    onClose={() => this.setState({ photo: 0 })}
                    onPrev={() => this.onPrev()}
                    onNext={() => this.onNext()}
                    prefix="elis"
                />
                <Slideshow
                    photo={photoReal}
                    onClose={() => this.setState({ photoReal: 0 })}
                    onPrev={() => this.onPrevReal()}
                    onNext={() => this.onNextReal()}
                    prefix="real"
                />
                <div className="elis__hero">
                    <img src={IMAGES['hero-elis.webp']} alt="hero elis" />
                    <div className="elis__hero__content">
                        <Sections links={{
                            '/': __('Domov'),
                            '': __('Elis 25m2'),
                        }} />
                        <div className="elis__hero__content__data">
                            <div className="elis__hero__content__data__title">{__('Elis 25m2')}</div>
                            <div className="elis__hero__content__data__text" dangerouslySetInnerHTML={{ __html: __('Nízkoenergetický 1-izbový model s rozmermi 2,5m x 10m. Obsahuje spálňu, kuchyňu, kúpeľňu a vstupnú chodbu.') }} />
                            <div className="elis__hero__content__data__button">
                                <ButtonBase onClick={() => {}}>{`${formatAmount(ELIS_PRICE)} - ${__('finálna cena na kľúč vrátane základov')}`}</ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elis__hero-photos">
                    {_.map([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15], id => {
                        return (
                            <ButtonBase onClick={() => this.setState({ photoReal: toNumber(id) })} className="elis__first__photos__photo" key={id}>
                                <img src={IMAGES[`real-photo-${id}-mini.webp`]} />
                            </ButtonBase>
                        );
                    })}
                </div>
                <div className="elis__values">
                    <div className="elis__values__content">
                        <div className="elis__values__content__title">{__('3 dôležité veci')}</div>
                        <div className="elis__values__content__panels">
                            <div className="elis__values__content__panels__panel">
                                <EngineeringIcon />
                                <div className="elis__values__content__panels__panel__title">{__('Nie je potrebné povolenie')}</div>
                                <div className="elis__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Dom má zastavanú plochu 25m2 a spadá pod <b>ohlásenie drobnej stavby</b>. Nemusíte tak čakať na dlhé schvaľovanie stavebného povolenia.') }} />
                            </div>
                            <div className="elis__values__content__panels__panel">
                                <DescriptionIcon />
                                <div className="elis__values__content__panels__panel__title">{__('Nízkoenergetický dom')}</div>
                                <div className="elis__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Nikto vám na trhu neponúkne také množstvo tepelnej izolácie na plochu 25m2. Vďaka tomu sú naše domy radené v kategórii <b>A0 ako nízkoenergetické</b>.') }} />
                            </div>
                            <div className="elis__values__content__panels__panel">
                                <ForumIcon />
                                <div className="elis__values__content__panels__panel__title">{__('Kvalitné prevedenie')}</div>
                                <div className="elis__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Staviame len z kvalitných materiálov. Pri výbere všetkých komponentov do domu nepozeráme na cenu ale kvalitu. ') }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elis__first">
                    <div className="elis__first__content">
                        <div className="elis__first__content__section">{__('Vybavenie')}</div>
                        <div className="elis__first__content__title">{__('Čo všetko je zahrnuté v cene?')}</div>
                        <div className="elis__first__content__text" dangerouslySetInnerHTML={{ __html: __('Naša cena je <b>férová a konečná.</b> V cene máte zahrnuté všetko vrátane <b>dopravy</b> a <b>vybudovania základov</b> na vašom pozemku. Pripravili sme pre vás aj konkrétny zoznam.') }} />
                        <div className="elis__first__content__photos" dangerouslySetInnerHTML={{ __html: __('Máme k dispozícii aj vzorový dom, radi vám ho ukážeme :)') }} />
                        <div className="elis__first__content__button">
                            <ButtonBase onClick={() => {}}>{`${__('Celková cena na kľúč')}: ${formatAmount(ELIS_PRICE)}`}</ButtonBase>
                        </div>
                        <div className="elis__first__content__panels">
                            <div className="elis__first__content__panels__left">
                                <div className="elis__first__content__panels__left__photo">
                                    <img src={IMAGES['real-photo-1.webp']} />
                                </div>
                                <div className="elis__first__content__panels__left__texts">
                                    <div className="elis__first__content__panels__left__texts__text">
                                        <div className="elis__first__content__panels__left__texts__text__title">{__('Nadštandartné použitie izolácie')}</div>
                                        <div className="elis__first__content__panels__left__texts__text__value" dangerouslySetInnerHTML={{ __html: __('V stene 14cm sklenná vata a 10 cm polystyrén na fasáde. <b>Celkovo 24cm.</b> V podlahe 24 cm sklennej vaty a 4 cm XPS. <b>Celkovo 28 cm.</b> V streche <b>celkovo 28 cm</b> sklennej vaty.') }} />
                                    </div>
                                    <div className="elis__first__content__panels__left__texts__text">
                                        <div className="elis__first__content__panels__left__texts__text__title">{__('Kvalitné materiály')}</div>
                                        <div className="elis__first__content__panels__left__texts__text__value" dangerouslySetInnerHTML={{ __html: __('Kuchyňa z IKEA, nábytok na mieru, okná REHAU, izolácie Isover, Knauf, strecha Firestone, elektro Legrand, vruty Isometall.') }} />
                                    </div>
                                </div>
                            </div>
                            <div className="elis__first__content__panels__right">
                                <div className="elis__first__content__panels__right__row">
                                    <div className="elis__first__content__panels__right__row__number">01</div>
                                    <div className="elis__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Inštalácia základov na vašom pozemku formou kvalitných C8 zemných vrutov <b>Isometall.</b>') }} />
                                </div>
                                <div className="elis__first__content__panels__right__row">
                                    <div className="elis__first__content__panels__right__row__number">02</div>
                                    <div className="elis__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Vypracovanie projektu a podkladov pre ohlásenie drobnej stavby.') }} />
                                </div>
                                <div className="elis__first__content__panels__right__row">
                                    <div className="elis__first__content__panels__right__row__number">03</div>
                                    <div className="elis__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('<b>Kompletné zariadenie:</b> kuchynská linka so spotrebičmi, jedálenský stôl so stoličkami, interiérové dvere, <b>klimatizácia</b>, podlahy, vstavané skrine, postel s matracom, vybavenie kúpeľne a bojler.') }} />
                                </div>
                                <div className="elis__first__content__panels__right__row">
                                    <div className="elis__first__content__panels__right__row__number">04</div>
                                    <div className="elis__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Rozvody elektro a vody, pripravené vývody z domu na pripojenie. <b>Vykurovanie elektrickými konvektorami</b>, lokálna rekuperácia vzduchu. Samozrejme aj bleskozvod.') }} />
                                </div>
                                <div className="elis__first__content__panels__right__row">
                                    <div className="elis__first__content__panels__right__row__number">05</div>
                                    <div className="elis__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Kvalitné plastové okná <b>s izolačným trojsklom</b>, sieťky proti hmyzu a interiérové žalúzie.') }} />
                                </div>
                                <div className="elis__first__content__panels__right__row">
                                    <div className="elis__first__content__panels__right__row__number">06</div>
                                    <div className="elis__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Vnútorné povrchy v kvalite Q3 a vymaľované. Kúpeľňa obklad a dlažba. <b>Fásada v bielej farbe s antracit doplnkami</b>. Schody a strieška nad dverami.') }} />
                                </div>
                                <div className="elis__first__content__panels__right__row">
                                    <div className="elis__first__content__panels__right__row__number">07</div>
                                    <div className="elis__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Preprava domu na váš pozemok.') }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elis__first__plan">
                        <ButtonBase onClick={() => this.setState({ photo: 10 })}>
                            <img src={IMAGES[`elis-plan.webp`]} />
                        </ButtonBase>
                    </div>
                    <div className="elis__first__photos">
                        {_.map([1,2,3,4,5,6,7,8,9,10], id => {
                            return (
                                <ButtonBase onClick={() => this.setState({ photo: toNumber(id) })} className="elis__first__photos__photo" key={id}>
                                    <img src={IMAGES[`elis-photo-${id}-mini.webp`]} />
                                </ButtonBase>
                            );
                        })}
                    </div>
                </div>
                <div className="elis__second">
                    <div className="elis__second__content">
                        <div className="elis__second__content__section">{__('Proces dodania')}</div>
                        <div className="elis__second__content__title">{__('Ako to bude prebiehať?')}</div>
                        <div className="elis__second__content__text" dangerouslySetInnerHTML={{ __html: __('Zhrnuli sme celý proces dodania do 4 krokov ktoré sme podrobnejšie opísali.') }} />
                        <div className="elis__second__content__panels">
                            <div className="elis__second__content__panels__panel">
                                <div className="elis__second__content__panels__panel__left">
                                    <div className="elis__second__content__panels__panel__left__title">{__('Prvá návšteva')}</div>
                                    <div className="elis__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Zavolajte nám a dohodneme si stretnutie u nás kde uvidíte <b>naživo aj vzorový dom</b>. Ak sa vám bude páčiť a budete si ho chcieť objednať, tak vám dáme počiatočné podklady domu pre stavebný úrad. Vašou úlohou je následne ísť na váš stavebný úrad a informatívne sa popýtať s našimi podkladmi <b>či by bola možná daná stavba na vašom pozemku.</b> Pri prvej návšteve si ešte od vás vypýtame adresu kde by mala byť daná stavba dodaná aby sme to mohli odkonzultovať s našim dopravcom či je možná doprava.') }} />
                                    <ButtonBase onClick={() => this.redirect('/kontakt')}>
                                        <span>{__('Kontakt na nás')}</span>
                                        <OpenInNewIcon />
                                    </ButtonBase>
                                </div>
                                <div className="elis__second__content__panels__panel__photo">
                                    <img src={IMAGES['real-photo-2.webp']} />
                                </div>
                            </div>
                            <div className="elis__second__content__panels__panel">
                                <div className="elis__second__content__panels__panel__left">
                                    <div className="elis__second__content__panels__panel__left__title">{__('Záloha, zmluva a vypracovanie PD')}</div>
                                    <div className="elis__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Ak vám stavebný úrad potvrdí že daná stavba môže byť na vašom pozemku a dopravca odobrí dodanie, tak si dohodneme dalšie stretnutie. <b>Pripravíme pre vás zmluvu</b> kde budete mať aj platobné údaje na uhradenie zálohy <b>vo výške 70% z celkovej sumy.</b> Po úhrade náš projektant začne s vypracovaním projektu domu priamo na váš pozemok. Projekt by mal byť hotový <b>do 45 dní.</b>') }} />
                                </div>
                                <div className="elis__second__content__panels__panel__photo">
                                    <img src={IMAGES['elis-2.webp']} />
                                </div>
                            </div>
                            <div className="elis__second__content__panels__panel">
                                <div className="elis__second__content__panels__panel__left">
                                    <div className="elis__second__content__panels__panel__left__title">{__('Podanie na stavebný úrad')}</div>
                                    <div className="elis__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Keď náš projektant dokončí váš projekt, tak <b>pripravíme pre vás podklady</b> pre ohlásenie drobnej stavby. Tieto podklady vám pošleme poštou alebo si pre ne môžte prísť osobne. Následne ich bude potrebné z vašej strany <b>podať na váš stavebný úrad.</b> Tento proces môže trvať 30 dní. <b>Zatiaľ ale budeme pracovať na vašom dome aby bol pripravený čo najskôr.</b>') }} />
                                </div>
                                <div className="elis__second__content__panels__panel__photo">
                                    <img src={IMAGES['elis-3.webp']} />
                                </div>
                            </div>
                            <div className="elis__second__content__panels__panel">
                                <div className="elis__second__content__panels__panel__left">
                                    <div className="elis__second__content__panels__panel__left__title">{__('Základy a dodanie')}</div>
                                    <div className="elis__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Po definitívnom schválení stavby stavebným úradom prídeme najprv <b>zrealizovať na váš pozemok základy</b>, následne dohodneme termín dopravy domu na váš pozemku. Pred definitívnou dopravou sa môžete prísť pozrieť do našej výroby na váš dom. Následne bude potrebné doplatiť z vašej strany <b>zvyšných 30% z celkovej sumy.</b>') }} />
                                </div>
                                <div className="elis__second__content__panels__panel__photo">
                                    <img src={IMAGES['real-photo-8.webp']} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elis__third">
                    <div className="elis__third__content">
                        <div className="elis__third__content__left">
                            <div className="elis__third__content__left__section">{__('Kvalita')}</div>
                            <div className="elis__third__content__left__title">{__('Dostanete to najlepšie')}</div>
                            <div className="elis__third__content__left__text" dangerouslySetInnerHTML={{ __html: __('Nadštandartné použitie izolácie v konštrukcii. Kvalitné šrobované spoje. Dvojitá vzduchotesnosť obálky domu. Sadrokartónové povrchy celoplošne tmelené v kvalite Q3. Kvalitný nábytok na mieru.') }} />
                        </div>
                        <div className="elis__third__content__right">
                            <div className="elis__third__content__right__item">
                                <div className="elis__third__content__right__item__name">{__('Izolácie, kategória A0')}</div>
                                <div className="elis__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['izolacie'].perex }} />
                            </div>
                            <div className="elis__third__content__right__item">
                                <div className="elis__third__content__right__item__name">{__('Spoje a konštrukcia')}</div>
                                <div className="elis__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['spoje-a-konstrukcia'].perex }} />
                            </div>
                            <div className="elis__third__content__right__item">
                                <div className="elis__third__content__right__item__name">{__('Dvojitá vzduchotesnosť')}</div>
                                <div className="elis__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['dvojita-vzduchotesnost'].perex }} />
                            </div>
                            <div className="elis__third__content__right__item">
                                <div className="elis__third__content__right__item__name">{__('Nábytok na mieru')}</div>
                                <div className="elis__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['nabytok-na-mieru'].perex }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elis__down">
                    <div className="elis__down__content">
                        <div className="elis__down__content__left">
                            <div className="elis__down__content__left__title">{__('Transparentnosť')}</div>
                            <div className="elis__down__content__left__text" dangerouslySetInnerHTML={{ __html: __('Dáme vám k dispozícii fotografie počas výstavby domu, stavebný denník prác a <b>faktúry nakúpeného tovaru</b>.') }} />
                            <ButtonBase onClick={() => this.redirect('/transparentnost')}>{__('Zobraziť viac')}</ButtonBase>
                        </div>
                        <div className="elis__down__content__photo">
                            <img src={IMAGES['transparentnost.webp']} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(ElisScreen));
