import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import PhoneIcon from '@mui/icons-material/Phone';
import {ButtonBase} from '@mui/material';
import {__, formatPhone} from '../functions';
import { fetchData, fetchSettings } from '../actions';
import { PHONE } from '../config';
import '../assets/styles/contact.css';

class ContactScreen extends Screen {

    renderScreen() {
        return (
            <div className="contact">
                <div className="contact__content">
                    <Sections links={{
                        '/': __('Domov'),
                        '': __('Kontakt'),
                    }} />
                    <div className="contact__content__title">{__('Kontakt')}</div>
                    <div className="contact__content__info">
                        <div className="contact__content__info__left">
                            <div className="contact__content__info__left__text" dangerouslySetInnerHTML={{ __html: __('Radi sa s vami uvidíme osobne, pokiaľ budete chcieť vidieť naše vzorové domy naživo. <b>Vždy nám ale dajte prosím vedieť dopredu.</b>') }} />
                            <ButtonBase onClick={() => window.location = `tel:${PHONE}`}>
                                <PhoneIcon />
                                <span>{formatPhone(PHONE)}</span>
                            </ButtonBase>
                        </div>
                        <div className="contact__content__info__right">
                            <div className="contact__content__info__right__panel">
                                <div className="contact__content__info__right__panel__title">{__('Showroom')}</div>
                                <div className="contact__content__info__right__panel__text" dangerouslySetInnerHTML={{ __html: __('2by4.sk<br/>Bratislavská 67<br/>900 24 Velký biel') }} />
                            </div>
                            <div className="contact__content__info__right__panel">
                                <div className="contact__content__info__right__panel__title">{__('Fakturačné údaje')}</div>
                                <div className="contact__content__info__right__panel__text" dangerouslySetInnerHTML={{ __html: __('2by4 s. r. o.<br/>IČO: 55144357<br/>DIČ: 2121894258<br/>Karpatské námestie 7770/10A<br/>831 06 Bratislava') }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact__map">
                    <iframe
                        loading="lazy"
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2658.781937995801!2d17.370932!3d48.21081600000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDjCsDEyJzM4LjkiTiAxN8KwMjInMTUuNCJF!5e0!3m2!1ssk!2ssk!4v1682584062163!5m2!1ssk!2ss"
                        height="600"
                        frameBorder="0"
                        allowFullScreen=""
                        data-rocket-lazyload="fitvidscompatible"
                        className="lazyloaded"
                        data-ll-status="loaded"
                    />
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(ContactScreen));
