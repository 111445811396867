import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import {__, formatAmount, toNumber} from '../functions';
import { fetchData, fetchSettings } from '../actions';
import {BLOG_ARTICLES, IMAGES, ZOJA_PRICE, ZOJA_PRICE_REGULAR} from '../config';
import '../assets/styles/zoja.css';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import {ButtonBase} from "@mui/material";
import {Slideshow} from "../components/Slideshow";
import _ from "lodash";

class ZojaScreen extends Screen {
    state = {
        photo: 0,
        variant: 1,
    };

    onPrev() {
        let { photo } = this.state;

        photo -= 1;

        if (photo < 1) {
            photo = 15;
        }

        this.setState({ photo });
    }

    onNext() {
        let { photo } = this.state;

        photo += 1;

        if (photo > 15) {
            photo = 1;
        }

        this.setState({ photo });
    }

    renderScreen() {
        const { photo, variant } = this.state;

        return (
            <div className="choose">
                <Slideshow
                    photo={photo}
                    onClose={() => this.setState({ photo: 0 })}
                    onPrev={() => this.onPrev()}
                    onNext={() => this.onNext()}
                    prefix="zoja"
                />
                <div className="zoja__hero">
                    <img src={IMAGES['hero-zoja.webp']} alt="hero elis" />
                    <div className="zoja__hero__content">
                        <Sections links={{
                            '/': __('Domov'),
                            '': __('Zoja 48m2'),
                        }} />
                        <div className="zoja__hero__content__data">
                            <div className="zoja__hero__content__data__title">{__('Zoja 48m2')}</div>
                            <div className="zoja__hero__content__data__text" dangerouslySetInnerHTML={{ __html: __('3-izbový alebo 2-izbový model. Obsahuje dve priestranné spálne, kuchyňu s obývačkou, kúpeľňu a vstupnú chodbu.') }} />
                            <div className="zoja__hero__content__data__button">
                                <ButtonBase onClick={() => {}}>{`${formatAmount(ZOJA_PRICE)} - ${__('finálna cena v stave holodom vrátane základov')}`}</ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="zoja__values">
                    <div className="zoja__values__content">
                        <div className="zoja__values__content__title">{__('3 veci, ktoré rozhodujú')}</div>
                        <div className="zoja__values__content__panels">
                            <div className="zoja__values__content__panels__panel">
                                <EngineeringIcon />
                                <div className="zoja__values__content__panels__panel__title">{__('Nie je potrebné povolenie')}</div>
                                <div className="zoja__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Domy inštalované na zemných vrutoch nepotrebujú stavebné povolenie, kedže nejde o pevné spojenie so zemou. Je určite potrebná konzultácia s vašim stavebným úradom, lebo každý úrad postupuje odlišne.') }} />
                            </div>
                            <div className="zoja__values__content__panels__panel">
                                <DescriptionIcon />
                                <div className="zoja__values__content__panels__panel__title">{__('Nízkoenergetický dom')}</div>
                                <div className="zoja__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Nikto vám na trhu neponúkne také množstvo tepelnej izolácie na plochu 48m2. Vďaka tomu sú naše domy radené v kategórii <b>A0 ako nízkoenergetické</b>.') }} />
                            </div>
                            <div className="zoja__values__content__panels__panel">
                                <ForumIcon />
                                <div className="zoja__values__content__panels__panel__title">{__('Kvalitné prevedenie')}</div>
                                <div className="zoja__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Staviame len z kvalitných materiálov. Pri výbere všetkých komponentov do domu nepozeráme na cenu ale kvalitu. ') }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="zoja__first">
                    <div className="zoja__first__content">
                        <div className="zoja__first__content__section">{__('Vybavenie')}</div>
                        <div className="zoja__first__content__title">{__('Čo všetko je zahrnuté v cene?')}</div>
                        <div className="zoja__first__content__text" dangerouslySetInnerHTML={{ __html: __('Naša cena je <b>férová a konečná.</b> V cene máte vyhotovenie domu v štádiu holodom <b>vrátane vybudovania základov</b> na vašom pozemku. Pripravili sme pre vás aj konkrétny zoznam.') }} />
                        <div className="zoja__first__content__photos" dangerouslySetInnerHTML={{ __html: __('Nižšie si viete pozrieť 3D vizualizácie. Radi vám ale naživo ukážeme náš menší model Elis 25m2 v štádiu na kľúč :)') }} />
                        <div className="zoja__first__content__button">
                            <ButtonBase onClick={() => {}}>{`${__('Celková cena holodom')}: ${formatAmount(ZOJA_PRICE)}`}</ButtonBase>
                        </div>
                        <div className="zoja__first__content__panels">
                            <div className="zoja__first__content__panels__left">
                                <div className="zoja__first__content__panels__left__photo">
                                    <img src={IMAGES[`zoja-1.webp`]} />
                                </div>
                                <div className="zoja__first__content__panels__left__texts">
                                    <div className="zoja__first__content__panels__left__texts__text">
                                        <div className="zoja__first__content__panels__left__texts__text__title">{__('Nadštandartné použitie izolácie')}</div>
                                        <div className="zoja__first__content__panels__left__texts__text__value" dangerouslySetInnerHTML={{ __html: __('V stene 14cm sklenná vata a 10 cm polystyrén na fasáde. <b>Celkovo 24cm.</b> V podlahe 24 cm sklennej vaty a 4 cm XPS. <b>Celkovo 28 cm.</b> V streche <b>celkovo 28 cm</b> sklennej vaty.') }} />
                                    </div>
                                    <div className="zoja__first__content__panels__left__texts__text">
                                        <div className="zoja__first__content__panels__left__texts__text__title">{__('Dvojitá vzduchotesnosť')}</div>
                                        <div className="zoja__first__content__panels__left__texts__text__value" dangerouslySetInnerHTML={{ __html: __('V rámci vzduchotesnosti stavby robíme nadštandard. Kvalitne ju vzduchotesne uzavrieme na vnútornej strane ale aj na vonkajšej strane.') }} />
                                    </div>
                                </div>
                            </div>
                            <div className="zoja__first__content__panels__right">
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">01</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Inštalácia základov na vašom pozemku formou kvalitných C8 zemných vrutov <b>Isometall.</b>') }} />
                                </div>
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">02</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Základný projekt architektúry, fotodokumentáciu výstavby, stavebný denník.') }} />
                                </div>
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">03</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Konštrukcia KVH + OSB. Parotesná fólia a pásky Knauf LDS 100. Šikmá strecha šindel asfaltový IKO.') }} />
                                </div>
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">04</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Izolácia Knauf minerálna vlna (140mm v stene, 200mm v podlahe a 240mm v strope).') }} />
                                </div>
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">05</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Rozvody elektro a vody, pripravené vývody z domu na pripojenie. Príprava na klimatizáciu.') }} />
                                </div>
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">06</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Kvalitné plastové okná <b>s izolačným trojsklom</b>, sieťky proti hmyzu a interiérové žalúzie. Vchodové dvere.') }} />
                                </div>
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">07</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Vnútorné povrchy v kvalite <b>Q3 a vymaľované</b>. Kúpeľňa s vaňou a vstavaným WC bez obkladu a dlažby. Fasáda EPS polystyrén 100mm, lepidlo bez farby.') }} />
                                </div>
                                <div className="zoja__first__content__panels__right__row">
                                    <div className="zoja__first__content__panels__right__row__number">08</div>
                                    <div className="zoja__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('<b>Preprava domu</b> na váš pozemok.') }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="zoja__first__plan-buttons">
                        <ButtonBase onClick={() => this.setState({ variant: 1 })} className={variant === 1 ? 'active' : ''}>
                            {__('3-izbová varianta')}
                        </ButtonBase>
                        <ButtonBase onClick={() => this.setState({ variant: 2 })} className={variant === 2 ? 'active' : ''}>
                            {__('2-izbová varianta')}
                        </ButtonBase>
                    </div>
                    <div className="zoja__first__plan">
                        <ButtonBase onClick={() => this.setState({ photo: variant === 1 ? 10 : 11 })}>
                            <img src={IMAGES[variant === 1 ? `zoja-plan.webp` : 'zoja-plan2.webp']} />
                        </ButtonBase>
                    </div>
                    <div className="zoja__first__photos">
                        {_.map([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15], id => {
                            return (
                                <ButtonBase onClick={() => this.setState({ photo: toNumber(id) })} className="zoja__first__photos__photo" key={id}>
                                    <img src={IMAGES[`zoja-photo-${id}-mini.webp`]} />
                                </ButtonBase>
                            );
                        })}
                    </div>
                </div>
                <div className="zoja__second">
                    <div className="zoja__second__content">
                        <div className="zoja__second__content__section">{__('Proces dodania')}</div>
                        <div className="zoja__second__content__title">{__('Ako to bude prebiehať?')}</div>
                        <div className="zoja__second__content__text" dangerouslySetInnerHTML={{ __html: __('Zhrnuli sme celý proces dodania do 3 krokov ktoré sme podrobnejšie opísali.') }} />
                        <div className="zoja__second__content__panels">
                            <div className="zoja__second__content__panels__panel">
                                <div className="zoja__second__content__panels__panel__left">
                                    <div className="zoja__second__content__panels__panel__left__title">{__('Prvá návšteva')}</div>
                                    <div className="zoja__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Zavolajte nám a dohodneme si stretnutie u nás kde uvidíte <b>naživo aj vzorový dom nášho menšieho modelu Elis 25m2</b>. Pred stretnutím si od vás vypýtame adresu kde by mala byť daná stavba dodaná aby sme to mohli odkonzultovať s našim dopravcom či je možná doprava.') }} />
                                    <ButtonBase onClick={() => this.redirect('/kontakt')}>
                                        <span>{__('Kontakt na nás')}</span>
                                        <OpenInNewIcon />
                                    </ButtonBase>
                                </div>
                                <div className="zoja__second__content__panels__panel__photo">
                                    <img src={IMAGES['real-photo-2.webp']} />
                                </div>
                            </div>
                            <div className="zoja__second__content__panels__panel">
                                <div className="zoja__second__content__panels__panel__left">
                                    <div className="zoja__second__content__panels__panel__left__title">{__('Záloha, zmluva')}</div>
                                    <div className="zoja__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('<b>Pripravíme pre vás zmluvu</b> kde budete mať aj platobné údaje na uhradenie zálohy <b>vo výške 70% z celkovej sumy.</b> Po úhrade začneme s výstavbou domu u nás. Dom by mal byť hotový <b>do 90 dní.</b>') }} />
                                </div>
                                <div className="zoja__second__content__panels__panel__photo">
                                    <img src={IMAGES['elis-2.webp']} />
                                </div>
                            </div>
                            <div className="zoja__second__content__panels__panel">
                                <div className="zoja__second__content__panels__panel__left">
                                    <div className="zoja__second__content__panels__panel__left__title">{__('Základy a dodanie')}</div>
                                    <div className="zoja__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Po dokončený domu si dohodneme ďalšie stretnutie u nás kde uvidíte hotový dom naživo. Následne bude potrebné doplatiť z vašej strany <b>zvyšných 30% z celkovej sumy</b>. Po úhrade prídeme najprv <b>zrealizovať na váš pozemok základy</b>, následne dohodneme termín dopravy domu na váš pozemku.') }} />
                                </div>
                                <div className="zoja__second__content__panels__panel__photo">
                                    <img src={IMAGES['real-photo-8.webp']} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="zoja__third">
                    <div className="zoja__third__content">
                        <div className="zoja__third__content__left">
                            <div className="zoja__third__content__left__section">{__('Kvalita')}</div>
                            <div className="zoja__third__content__left__title">{__('Dostanete to najlepšie')}</div>
                            <div className="zoja__third__content__left__text" dangerouslySetInnerHTML={{ __html: __('Nadštandartné použitie izolácii v konštrukcii. Kvalitné šrobované spoje. Dvojitá vzduchotesnosť obálky domu Sadrokartónové povrchy celoplošne tmelené v kvalite Q3.') }} />
                        </div>
                        <div className="zoja__third__content__right">
                            <div className="zoja__third__content__right__item">
                                <div className="zoja__third__content__right__item__name">{__('Izolácie, kategória A0')}</div>
                                <div className="zoja__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['izolacie'].perex }} />
                            </div>
                            <div className="zoja__third__content__right__item">
                                <div className="zoja__third__content__right__item__name">{__('Spoje a konštrukcia')}</div>
                                <div className="zoja__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['spoje-a-konstrukcia'].perex }} />
                            </div>
                            <div className="zoja__third__content__right__item">
                                <div className="zoja__third__content__right__item__name">{__('Dvojitá vzduchotesnosť')}</div>
                                <div className="zoja__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['dvojita-vzduchotesnost'].perex }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="zoja__down">
                    <div className="zoja__down__content">
                        <div className="zoja__down__content__left">
                            <div className="zoja__down__content__left__title">{__('Transparentnosť')}</div>
                            <div className="zoja__down__content__left__text" dangerouslySetInnerHTML={{ __html: __('Dáme vám k dispozícii fotografie počas výstavby domu, stavebný denník prác.') }} />
                            <ButtonBase onClick={() => this.redirect('/transparentnost')}>{__('Zobraziť viac')}</ButtonBase>
                        </div>
                        <div className="zoja__down__content__photo">
                            <img src={IMAGES['transparentnost.webp']} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(ZojaScreen));
