import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Route, Routes } from 'react-router-dom';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { Navigate } from './Navigate';
import BlogScreen from '../screens/BlogScreen';
import ArticleScreen from '../screens/ArticleScreen';
import CustomScreen from '../screens/CustomScreen';
import ElisScreen from '../screens/ElisScreen';
import CareScreen from '../screens/CareScreen';
import ContactScreen from '../screens/ContactScreen';
import GoaliesScreen from '../screens/GoaliesScreen';
import ZojaScreen from "../screens/ZojaScreen";
import AriaScreen from "../screens/AriaScreen";
import LunaScreen from "../screens/LunaScreen";
import VeraScreen from "../screens/VeraScreen";
import Warning from "./Warning";

class Content extends Navigate {

    render() {
        const { location, navigate, params } = this.props;

        return (
            <div className="content">
                <Warning location={location} navigate={navigate} params={params} />
                <Menu location={location} navigate={navigate} params={params} />
                <div className="content__screen">
                    <Routes>
                        <Route path="/nasi-dodavatelia" element={<GoaliesScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/showroom" element={<ContactScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/kontakt" element={<ContactScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/transparentnost" element={<CareScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/vera" element={<VeraScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/elis" element={<ElisScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/zoja" element={<ZojaScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/" element={<ElisScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="*" element={<ElisScreen location={location} navigate={navigate} params={params} />} />
                    </Routes>
                </div>
                <Footer location={location} navigate={navigate} params={params} />
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(Content));
