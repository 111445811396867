import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import EmailIcon from '@mui/icons-material/Email';
import { ButtonBase } from '@mui/material';
import { fetchData, fetchSettings } from '../actions';
import {EMAIL, IMAGES} from '../config';
import '../assets/styles/goalies.css';

class GoaliesScreen extends Screen {

    renderScreen() {
        return (
            <div className="goalies">
                <div className="goalies__hero">
                    <img src={IMAGES['hero-dodavatelia.webp']} alt="hero dodavatelia" />
                    <div className="goalies__hero__content">
                        <Sections links={{
                            '/': __('Domov'),
                            '': __('Naši dodávatelia'),
                        }} />
                        <div className="goalies__hero__content__data">
                            <div className="goalies__hero__content__data__title">{__('Naši dodávatelia')}</div>
                            <div className="goalies__hero__content__data__text" dangerouslySetInnerHTML={{ __html: __('Ide nám o to aby ste vy ako náš zákaznik dostali <b>maximálnu kvalitu</b>, preto si vyberáme len kvalitné materiály od našich dodávateľov.') }} />
                        </div>
                    </div>
                </div>
                <div className="goalies__content">
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-3.webp']} />
                        </div>
                        <div className="goalies__content__item__title">Drevoma</div>
                        <div className="goalies__content__item__team">{__('KVH, OSB')}</div>
                    </div>
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-7.png']} />
                        </div>
                        <div className="goalies__content__item__title">Isover</div>
                        <div className="goalies__content__item__team">{__('Sklenná vata, polystyrén')}</div>
                    </div>
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-5.webp']} />
                        </div>
                        <div className="goalies__content__item__title">Knauf</div>
                        <div className="goalies__content__item__team">{__('Parotesné fólie, pásky, sadrokartón')}</div>
                    </div>
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-6.webp']} />
                        </div>
                        <div className="goalies__content__item__title">Firestone</div>
                        <div className="goalies__content__item__team">{__('EPDM fólie a príslušenstvo')}</div>
                    </div>
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-1.webp']} />
                        </div>
                        <div className="goalies__content__item__title">Skladové okná</div>
                        <div className="goalies__content__item__team">{__('Okná, sieťky, žalúzie a parapety')}</div>
                    </div>
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-2.webp']} />
                        </div>
                        <div className="goalies__content__item__title">Milwaukee</div>
                        <div className="goalies__content__item__team">{__('Náradie na výrobu')}</div>
                    </div>
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-4.webp']} />
                        </div>
                        <div className="goalies__content__item__title">Hasoft</div>
                        <div className="goalies__content__item__team">{__('Parotesné pásky')}</div>
                    </div>
                    <div className="goalies__content__item">
                        <div className="goalies__content__item__photo">
                            <img src={IMAGES['partner-8.webp']} />
                        </div>
                        <div className="goalies__content__item__title">Legrand</div>
                        <div className="goalies__content__item__team">{__('Elektroinštalácia')}</div>
                    </div>
                </div>
                <div className="goalies__down">
                    <div className="goalies__down__content">
                        <div className="goalies__down__content__left">
                            <div className="goalies__down__content__left__title">{__('Chcete byť tiež našim dodávateľom?')}</div>
                            <div className="goalies__down__content__left__text" dangerouslySetInnerHTML={{ __html: __('Pošlite nám viac informácii o svojich výrobkoch na') }} />
                            <ButtonBase onClick={() => window.location = `mailto:${EMAIL}`}>
                                <EmailIcon />
                                <span>{EMAIL}</span>
                            </ButtonBase>
                        </div>
                        <div className="goalies__down__content__photo">
                            <img src={IMAGES['suppliers-1.webp']} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(GoaliesScreen));
