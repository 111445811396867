import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Screen, Sections } from '../components';
import {__, toNumber} from '../functions';
import { fetchData, fetchSettings } from '../actions';
import {BLOG_CATEGORIES, BLOG_ARTICLES, IMAGES} from "../config";
import '../assets/styles/blog.css';

class BlogScreen extends Screen {
    state = {
        category: 0,
    };

    async componentDidMount() {
        if (this.getPath() === '/kvalita') {
            this.setState({ category: 2 });
        }

        return super.componentDidMount();
    }

    renderScreen() {
        const { category } = this.state;

        return (
            <div className="blog">
                <div className="blog__content">
                    <Sections links={{
                        '/': __('Domov'),
                        '': __('Blog'),
                    }} />
                    <div className="blog__content__title">{__('BLog')}</div>
                    <div className="blog__content__text">{__('Novinky a zaujímavosti zo sveta drevostavieb. Občas tu budeme ukazovat aj aký sme šikovní :)')}</div>
                    <div className="blog__content__data">
                        <div className="blog__content__data__categories">
                            <div className="blog__content__data__categories__content">
                                <ButtonBase onClick={() => {}} className="blog__content__data__categories__content__title">{__('Témy')}</ButtonBase>
                                <ButtonBase
                                    onClick={() => this.setState({ category: 0 })}
                                    className={`blog__content__data__categories__content__category ${category === 0 ? 'active' : ''}`}
                                >{__('Všetko')}</ButtonBase>
                                {_.map(BLOG_CATEGORIES, (name, id) => {
                                    return (
                                        <ButtonBase
                                            onClick={() => this.setState({ category: toNumber(id) })}
                                            className={`blog__content__data__categories__content__category ${category === toNumber(id) ? 'active' : ''}`}
                                            key={id}
                                        >{name}</ButtonBase>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="blog__content__data__articles">
                            {_.map(BLOG_ARTICLES, (article, uri) => {
                                if (category > 0 && !_.includes(article.categories, category)) {
                                    return null;
                                }

                                const categoriesNames = _.reduce(article.categories, (result, id) => {
                                    return [ ...result, BLOG_CATEGORIES[id] ];
                                }, []);

                                return (
                                    <ButtonBase onClick={() => this.redirect(`/blog/${uri}`)} className="blog__content__data__articles__article" key={uri}>
                                        <div className="blog__content__data__articles__article__photo">
                                            <img src={IMAGES[article.image]} />
                                        </div>
                                        <div className="blog__content__data__articles__article__categories">{_.join(categoriesNames, ' | ')}</div>
                                        <div className="blog__content__data__articles__article__title">{article.name}</div>
                                        <div className="blog__content__data__articles__article__text" dangerouslySetInnerHTML={{ __html: article.perex }} />
                                        <ButtonBase onClick={() => this.redirect(`/blog/${uri}`)}>
                                            <span>{__('Prečítať článok')}</span>
                                            <OpenInNewIcon />
                                        </ButtonBase>
                                    </ButtonBase>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(BlogScreen));
