import { FETCH_DATA } from '../actions/types';

const DATA_DEFAULT_STATE = [];

/**
 * Reducer pre data.
 */
const DataReducer = (state = DATA_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_DATA:
            // Nacitanie data
            return payload;

        default:
            return state;
    }
};

export default DataReducer;
