import React, { Component } from 'react';
import _ from 'lodash';
import '../assets/styles/slideshow.css';
import {toNumber} from "../functions";
import {IMAGES} from "../config";
import {ButtonBase} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrevIcon from "@mui/icons-material/ArrowBackIos";
import NextIcon from "@mui/icons-material/ArrowForwardIos";

class Slideshow extends Component {
    static defaultProps = {
        photo: 0,
        onClose: () => {},
        onPrev: () => {},
        onNext: () => {},
        prefix: 'elis',
    };

    render() {
        const { prefix, photo, onClose, onPrev, onNext } = this.props;

        if (toNumber(photo) === 0) {
            return null;
        }

        return (
            <div className="slideshow">
                <ButtonBase onClick={() => onClose()} className="slideshow__background" disableRipple />
                <ButtonBase onClick={() => onClose()} className="slideshow__close">
                    <CloseIcon />
                </ButtonBase>
                <div className="slideshow__photo">
                    <img src={IMAGES[`${prefix}-photo-${photo}.webp`]} />
                    <ButtonBase onClick={() => onPrev()} className="slideshow__prev">
                        <PrevIcon />
                    </ButtonBase>
                    <ButtonBase onClick={() => onNext()} className="slideshow__next">
                        <NextIcon />
                    </ButtonBase>
                </div>
            </div>
        );
    }
}

export { Slideshow };
