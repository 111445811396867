import React from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from './Navigate';
import { __, getUrlPath } from '../functions';
import { COOKIE_TOKEN_NAME, COOKIE_SETTINGS } from '../config';

class Screen extends Navigate {
    title = '';

    async componentDidMount() {
        this.scrollToTop();

        const { cookies } = this.props;

        if (this.getPath() === '/logout') {
            // Chceme odhlasit
            // Zmazeme token
            cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

            window.location = getUrlPath('/');
            return false;
        }

        // Nasetujeme title
        this.setTitle(this.title);

        // Nastavime token
        this.setToken();

        // Nacitame data
        await this.fetch();
    }

    setToken() {
        const { cookies } = this.props;

        // Pozrieme sa ci mame ulozeny token
        const token = cookies.get(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

        global.token = !_.isUndefined(token) ? token : '';
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    async fetch() {
        if (!_.has(this.props, 'fetchData') || !_.has(this.props, 'data')) {
            return;
        }

        const { fetchData, fetchSettings } = this.props;

        if (!_.isEmpty(this.props.data)) {
            // Uz mame nacitane data
            return;
        }

        // Nacitame nastavenia
        await fetchSettings(this);

        // Nacitame data
        await fetchData(this);
    }

    setTitle(title) {
        const domain_name = __('2by4.sk - Kvalitné celoročné domy do 50m2');

        // Nasetujeme title
        document.title = !_.isEmpty(title) ? `${title} - ${domain_name}` : domain_name;
    }

    renderScreen() {
        return null;
    }

    renderLoading() {
        return (
            <div id="screen-loading">
                <CircularProgress color="primary" size={50} />
            </div>
        );
    }

    render() {
        if (_.has(this.props, 'data') && !_.isEmpty(this.props.data)) {
            // Su nacitane data
            return this.renderScreen();
        }

        return this.renderLoading();
    }
}

export { Screen };
