import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Navigate } from './Navigate';
import '../assets/styles/sections.css';

class Sections extends Navigate {
    static defaultProps = {
        links: {},
    };

    render() {
        const { links } = this.props;

        return (
            <Breadcrumbs className="sections">
                {_.map(links, (name, url) => <Link to={url} key={url}>{name}</Link>)}
            </Breadcrumbs>
        );
    }
}

export { Sections };
