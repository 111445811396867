import _ from 'lodash';
import { FETCH_SETTINGS, CHANGE_SETTING } from './types';
import { COOKIE_SETTINGS_NAME, COOKIE_SETTINGS } from '../config';

export const fetchSettings = (screen) => {
    let settings = screen.props.cookies.get(COOKIE_SETTINGS_NAME, COOKIE_SETTINGS);
    settings = !_.isUndefined(settings) ? settings : {};

    return dispatch => dispatch({ type: FETCH_SETTINGS, payload: settings });
};

export const changeSetting = (screen, type, value) => {
    const { cookies, settings } = screen.props;

    // Ulozime do cookie
    cookies.set(COOKIE_SETTINGS_NAME, JSON.stringify({ ...settings, [type]: value }), COOKIE_SETTINGS);

    return dispatch => dispatch({ type: CHANGE_SETTING, payload: { type, value } });
};
