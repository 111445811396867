import { getAssets } from './functions/getAssets';
import { __ } from "./functions/__";

export const IMAGES = getAssets(require.context('./assets/img/', false, /\.(png|jpe?g|svg|gif|webp)$/));
export const COOKIE_TOKEN_NAME = '2by4-api-token';
export const COOKIE_SETTINGS_NAME = '2by4-settings';
export const COOKIE_SETTINGS = process.env.NODE_ENV && process.env.NODE_ENV === 'production'
    ? { path: '/', domain: window.location.host }
    : { path: '/' };
export const DEVELOPMENT_URL = 'http://2by4.loc';
export const PRODUCTION_URL = 'https://api.2by4.sk';
export const PHONE = '+421944468464';
export const EMAIL = 'info@2by4.sk';
export const ELIS_PRICE = 41999.99;
export const VERA_PRICE = 49999.99;
export const ZOJA_PRICE = 29999.99;
export const ARIA_PRICE = 79999.99;
export const LUNA_PRICE = 99999.99;
export const BLOG_CATEGORIES = {
    1: __('Stavebný úrad'),
    2: __('Kvalita'),
};
export const BLOG_ARTICLES = {
    'stavebne-povolenie-alebo-ohlaska': {
        image: 'blog-1.webp',
        categories: [1],
        name: __('Stavebné povolenie alebo ohláška?'),
        author: 'Martin Rác',
        time: __('5 minút'),
        perex: __('Je potrebné žiadať o stavebné povolenie alebo postačuje len ohlásenie drobnej stavby?'),
        text: __('<p>Na začiatku článku by som chcel upozorniť na problematiku mobilných domov a stavebného povolenia ako takého. <b>Väčšina konkurenčných firiem</b> ktoré ponúkajú mobilné domy tak prezentujú že nie je potrebná mať stavebné povolenie kedže sa jedná o mobilný dom. Pravda je taká že podľa definície zákona ak nie je dom pevne spojený so zemou základmi ale príklad zemnými vrutami tak sa <b>nejedná o pevne spojenie so zemou</b> a tym pádom ani o stavbu.</p><p>Klúčové v tom či potrebujete stavebné povolenie alebo ohlášku je <b>samotné pripojenie na inžinierske siete</b>. Vtedy sa už aj mobilný dom považuje za pevne spojený so zemou a tym pádom aj ako stavba.</p><p><b>My vás nebudeme zavádzať</b> že nepotrebujete nič riešiť so stavebným úradom, ale naopak radi vám poradíme čo treba riešiť a <b>pripravíme vám kompletné podklady.</b></p><p>To či potrebujete stavebné povolenie alebo len ohlásenie drobnej stavby u nás záleži <b>najprv na zastavanej ploche.</b> Náš najmenší model <b>Elis</b> je navrhnutý na 25m2 kde vo väčšine prípadov <b>stačí len ohlásenie drobnej stavby.</b> Ak by mal dom nad 25m2 tak musíte žiadať stavebné povolenie.</p><p>V niektorých prípadoch ale aj náš menší model Elis 25m2 <b>potrebuje stavebné povolenie</b> a to v prípade ak ešte na pozemku nemáte žiadnu inú stavbu riešenú formou stavebného povolenia. Pretože ohlásenie drobnej stavby musí splnať to že ide o <b>doplnkovú stavbu k hlavnej stavbe.</b> Ak ide o prvú stavbu tak budete musieť žiadať stavebné povolenie. Ak už ale na pozemku máte stavbu tak vám u nás stačí <b>len ohlásenie drobnej stavby.</b></p>'),
    },
    'izolacie': {
        image: 'public-2.webp',
        categories: [2],
        name: __('Izolácie, kategória A0'),
        author: 'Martin Rác',
        time: __('5 minút'),
        perex: __('Naše domy sú posúdené v najnižšej kategórii A0 a teda vhodné na celoročné bývanie. V stene máme <b>24cm</b>, v streche <b>28cm</b> a v podlahe <b>28cm</b> izolácie.'),
        text: __(''),
    },
    'spoje-a-konstrukcia': {
        image: 'public-3.webp',
        categories: [2],
        name: __('Spoje a konštrukcia'),
        author: 'Martin Rác',
        time: __('5 minút'),
        perex: __('Kvalitná konštrukcia z <b>KVH a OSB</b>. Hlavné spoje sú <b>šrobované</b> kvalitnými šróbami nie klincami.'),
        text: __(''),
    },
    'dvojita-vzduchotesnost': {
        image: 'public-4.webp',
        categories: [2],
        name: __('Dvojitá vzduchotesnosť'),
        author: 'Martin Rác',
        time: __('5 minút'),
        perex: __('Pre kvalitné prevedenie domu je dôležitá <b>vzduchotesnosť obálky domu.</b> My ju riešime na dvoch miestach a to na <b>vonkajšej</b> strane domu aj na <b>vnútornej</b> strane.'),
        text: __(''),
    },
    'sadrokarton-q3': {
        image: 'public-5.webp',
        categories: [2],
        name: __('Sadrokartón Q3'),
        author: 'Martin Rác',
        time: __('5 minút'),
        perex: __('Sadrokartón riešime v <b>kvalite Q3</b> čo znamená celoplošné tmelený ešte navyše finálnou vrstvou. Spoje sadrokartónu sú <b>prepáskované kvalitnými páskami</b> aby vám nepraskali rohy.'),
        text: __(''),
    },
    'hydroizolacia-podlahy': {
        image: 'public-6.webp',
        categories: [2],
        name: __('Hydroizolácia podlahy'),
        author: 'Martin Rác',
        time: __('5 minút'),
        perex: __('Klasické ukončenie podlahy zo spodnej strany mobilných domov býva OSB. My dávame tiež OSB ale ako finálnu hydroizolačnú vrstvu pridávame zo spodu aj <b>kvalitnú EPDM fóliu</b> v jednom kuse.'),
        text: __('<p>Toto riešenie je nákladné ale jeho výhoda je kompletná hydroizolácia spodnej strany domu. EPDM fólia <b>ochraňuje spodnú vrstvu OSB pred vlhkosťou.</b> Vďaka tomuto riešeniu si môžete spodok domu kompletne uzavrieť.</p>'),
    },
    'nabytok-na-mieru': {
        image: 'public-7.webp',
        categories: [2],
        name: __('Nábytok na mieru'),
        author: 'Martin Rác',
        time: __('5 minút'),
        perex: __('Kvalitné šatníkové skrine na mieru s posuvnými dverami. Postel na mieru s matracom. <b>Kuchyňa na mieru z IKEA</b> so vstavanými spotrebičmi. Plus ako nadštandard <b>orámovanie okien.</b>'),
        text: __(''),
    },
};
