export const getUrlPath = uri => {
    if (global.language === 'en') {
        switch (uri) {
            case '/obchod':
                return '/shop';
        }
    }

    return uri;
};
