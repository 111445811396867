import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { ButtonBase } from '@mui/material';
import EmailIcon from '@mui/icons-material/MailOutline';
import _ from 'lodash';
import { Navigate } from './Navigate';
import {__, formatPhone} from '../functions';
import {EMAIL, IMAGES, PHONE} from '../config';
import '../assets/styles/footer.css';

class Footer extends Navigate {

    render() {
        const { data } = this.props;

        if (_.isEmpty(data)) {
            // Nie su nacitane data
            return null;
        }

        return (
            <div className="footer">
                <div className="footer__content">
                    <div className="footer__content__panels">
                        <div className="footer__content__panels__panel">
                            <div className="footer__content__panels__panel__slogan">{__('Kvalitné celoročné domy do 50m2')}</div>
                        </div>
                        <div className="footer__content__panels__panel">
                            <div className="footer__content__panels__panel__title">{__('O 2by4')}</div>
                            <ButtonBase onClick={() => this.redirect('/transparentnost')}>{__('Transparentnosť')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/nasi-dodavatelia')}>{__('Naši dodávatelia')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/showroom')}>{__('Showroom')}</ButtonBase>
                        </div>
                        <div className="footer__content__panels__panel">
                            <div className="footer__content__panels__panel__title">{__('Informácie k nákupu')}</div>
                            <ButtonBase onClick={() => this.redirect('/')}>{__('Obchodné podmienky')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/')}>{__('Reklamačné podmienky')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/')}>{__('Spracovanie osobných údajov')}</ButtonBase>
                        </div>
                        <div className="footer__content__panels__panel">
                            <div className="footer__content__panels__panel__title">{__('Kontakt')}</div>
                            <div className="footer__content__panels__panel__contact">
                                <div className="footer__content__panels__panel__contact__left">
                                    <EmailIcon />
                                </div>
                                <div className="footer__content__panels__panel__contact__right">
                                    <div className="footer__content__panels__panel__contact__right__value">{EMAIL}</div>
                                    <div className="footer__content__panels__panel__contact__right__text">{__('(bežne odpisujeme do 24 hodín)')}</div>
                                </div>
                            </div>
                            <div className="footer__content__panels__panel__contact">
                                <div className="footer__content__panels__panel__contact__left">
                                    <EmailIcon />
                                </div>
                                <div className="footer__content__panels__panel__contact__right">
                                    <div className="footer__content__panels__panel__contact__right__value">{formatPhone(PHONE)}</div>
                                    <div className="footer__content__panels__panel__contact__right__text">{__('(pondelok - nedela: 09:00 - 17:00)')}</div>
                                </div>
                            </div>
                            <div className="footer__content__panels__panel__social">
                                <a href="https://www.instagram.com/2by4.sk/" target="_blank">
                                    <img src={IMAGES['instagram.png']} alt="instagram" />
                                </a>
                                <a href="https://facebook.com/2by4sk" target="_blank">
                                    <img src={IMAGES['facebook.png']} alt="facebook" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer__content__down">
                        <div className="footer__content__down__text">{__('© 2024 2by4.sk. All rights reserved')}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps)(Footer));
