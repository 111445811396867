import React, { Component } from 'react';
import { SnackbarContent } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

class Message extends Component {
    static defaultProps = {
        type: 'success',
        icon: '',
        className: '',
    };

    icons = {
        success: <CheckCircleIcon />,
        error: <ErrorIcon />,
        info: <InfoIcon />,
        warning: <InfoIcon />,
    };

    render() {
        const { type, icon, children, className } = this.props;

        const icon_name = icon !== '' ? icon : type;

        return (
            <div className={`snackbar ${type} ${className}`}>
                <SnackbarContent
                    className="snackbar-content"
                    message={<span className="snackbar-message">{this.icons[icon_name]}{children}</span>}
                />
            </div>
        );
    }
}

export { Message };
