import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import {__, formatAmount, toNumber} from '../functions';
import { fetchData, fetchSettings } from '../actions';
import {BLOG_ARTICLES, IMAGES, LUNA_PRICE} from '../config';
import '../assets/styles/luna.css';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import {ButtonBase} from "@mui/material";
import {Slideshow} from "../components/Slideshow";
import _ from "lodash";

class LunaScreen extends Screen {
    state = {
        photo: 0,
    };

    onPrev() {
        let { photo } = this.state;

        photo -= 1;

        if (photo < 1) {
            photo = 10;
        }

        this.setState({ photo });
    }

    onNext() {
        let { photo } = this.state;

        photo += 1;

        if (photo > 10) {
            photo = 1;
        }

        this.setState({ photo });
    }

    renderScreen() {
        const { photo } = this.state;

        return (
            <div className="choose">
                <Slideshow
                    photo={photo}
                    onClose={() => this.setState({ photo: 0 })}
                    onPrev={() => this.onPrev()}
                    onNext={() => this.onNext()}
                    prefix="luna"
                />
                <div className="luna__hero">
                    <img src={IMAGES['hero-luna.webp']} alt="hero luna" />
                    <div className="luna__hero__content">
                        <Sections links={{
                            '/': __('Domov'),
                            '': __('Luna 96m2'),
                        }} />
                        <div className="luna__hero__content__data">
                            <div className="luna__hero__content__data__title">{__('Luna 96m2')}</div>
                            <div className="luna__hero__content__data__text" dangerouslySetInnerHTML={{ __html: __('4-izbový model. Obsahuje tri priestranné spálne, šatník, kuchyňu s obývačkou, kúpeľňu a vstupnú chodbu.') }} />
                            <div className="luna__hero__content__data__button">
                                <ButtonBase onClick={() => {}}>{`${formatAmount(LUNA_PRICE)} - ${__('finálna cena na kľúč vrátane základov')}`}</ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="luna__values">
                    <div className="luna__values__content">
                        <div className="luna__values__content__title">{__('3 veci, ktoré rozhodujú')}</div>
                        <div className="luna__values__content__panels">
                            <div className="luna__values__content__panels__panel">
                                <EngineeringIcon />
                                <div className="luna__values__content__panels__panel__title">{__('Kvalita')}</div>
                                <div className="luna__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Každý dom stavajú <b>priamo majitelia firmy</b> ktorí dohliadaju na kvalitu, technologické postupy a finálne prevedenie. Nesústredime sa na <b>kvantitu ale kvalitu</b>.') }} />
                                <ButtonBase onClick={() => this.redirect('/kvalita')}>
                                    <span>{__('Prečítať viac o tom ako staviame')}</span>
                                    <OpenInNewIcon />
                                </ButtonBase>
                            </div>
                            <div className="luna__values__content__panels__panel">
                                <DescriptionIcon />
                                <div className="luna__values__content__panels__panel__title">{__('Transparentnosť')}</div>
                                <div className="luna__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Dáme vám k dispozícii fotografie počas výstavby domu, stavebný denník prác a <b>faktúry nakúpeného tovaru</b>.') }} />
                                <ButtonBase onClick={() => this.redirect('/transparentnost')}>
                                    <span>{__('Zobraziť viac')}</span>
                                    <OpenInNewIcon />
                                </ButtonBase>
                            </div>
                            <div className="luna__values__content__panels__panel">
                                <ForumIcon />
                                <div className="luna__values__content__panels__panel__title">{__('Komunikácia')}</div>
                                <div className="luna__values__content__panels__panel__text" dangerouslySetInnerHTML={{ __html: __('Je tažké sa rozhodnúť ak neuvidíte konkrétny dom naživo. <b>Máme dostupné aj vzorové domy</b> na ktoré sa môžete prísť pozrieť a uvidíte čo kupujete. Stačí nám <b>zavolať a dohodnúť si stretnutie.</b>') }} />
                                <ButtonBase onClick={() => this.redirect('/kontakt')}>
                                    <span>{__('Ako sa dostanete k nám?')}</span>
                                    <OpenInNewIcon />
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="luna__first">
                    <div className="luna__first__content">
                        <div className="luna__first__content__section">{__('Vybavenie')}</div>
                        <div className="luna__first__content__title">{__('Čo všetko je zahrnuté v cene?')}</div>
                        <div className="luna__first__content__text" dangerouslySetInnerHTML={{ __html: __('Naša cena je <b>férová a konečná.</b> V cene máte zahrnuté všetko <b>vrátane vybudovania základov</b> na vašom pozemku. Pripravili sme pre vás aj konkrétny zoznam.') }} />
                        <div className="luna__first__content__photos" dangerouslySetInnerHTML={{ __html: __('Aktuálne ešte pracujeme na projekte a nemáme k dispozícii vzorový dom. Nižšie si ale viete pozrieť 3D vizualizácie. Radi vám ale naživo ukážeme náš menší model Elis 25m2 :)') }} />
                        <div className="luna__first__content__button">
                            <ButtonBase onClick={() => {}}>{`${__('Celková cena na kľúč')}: ${formatAmount(LUNA_PRICE)}`}</ButtonBase>
                        </div>
                        <div className="luna__first__content__panels">
                            <div className="luna__first__content__panels__left">
                                <div className="luna__first__content__panels__left__photo">
                                    <img src={IMAGES[`luna-1.webp`]} />
                                </div>
                                <div className="luna__first__content__panels__left__texts">
                                    <div className="luna__first__content__panels__left__texts__text">
                                        <div className="luna__first__content__panels__left__texts__text__title">{__('Nadštandartné použitie izolácie')}</div>
                                        <div className="luna__first__content__panels__left__texts__text__value" dangerouslySetInnerHTML={{ __html: __('V stene 14cm sklenná vata a 10 cm polystyrén na fasáde. <b>Celkovo 24cm.</b> V podlahe 24 cm sklennej vaty a 4 cm XPS. <b>Celkovo 28 cm.</b> V streche <b>celkovo 28 cm</b> sklennej vaty.') }} />
                                    </div>
                                    <div className="luna__first__content__panels__left__texts__text">
                                        <div className="luna__first__content__panels__left__texts__text__title">{__('Dvojitá vzduchotesnosť')}</div>
                                        <div className="luna__first__content__panels__left__texts__text__value" dangerouslySetInnerHTML={{ __html: __('V rámci vzduchotesnosti stavby robíme nadštandard. Kvalitne ju vzduchotesne uzavrieme na vnútornej strane ale aj na vonkajšej strane. Počas výstavby sa robí aj <b>test vzduchotesnosti externou firmou.</b> Výsledok skúšky dostanete k nahliadnutiu.') }} />
                                    </div>
                                </div>
                            </div>
                            <div className="luna__first__content__panels__right">
                                <div className="luna__first__content__panels__right__row">
                                    <div className="luna__first__content__panels__right__row__number">01</div>
                                    <div className="luna__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Inštalácia základov na vašom pozemku formou kvalitných C8 zemných vrutov <b>Isometall.</b>') }} />
                                </div>
                                <div className="luna__first__content__panels__right__row">
                                    <div className="luna__first__content__panels__right__row__number">02</div>
                                    <div className="luna__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Vypracovanie projektu pre stavebné povolenie.') }} />
                                </div>
                                <div className="luna__first__content__panels__right__row">
                                    <div className="luna__first__content__panels__right__row__number">03</div>
                                    <div className="luna__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('<b>Kompletné zariadenie:</b> kuchynská linka so spotrebičmi, jedálenský stôl so stoličkami, interierové dvere, podlahy, vstavané skrine, postele s matracom, vybavenie kúpeľne a bojler.') }} />
                                </div>
                                <div className="luna__first__content__panels__right__row">
                                    <div className="luna__first__content__panels__right__row__number">04</div>
                                    <div className="luna__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Rozvody elektro a vody, pripravené vývody z domu na pripojenie. <b>Vykurovanie elektrickými konvektorami</b>, lokálna rekuperácia vzduchu. Samozrejme aj bleskozvod. <b>Vrátane revíznych správ.</b>') }} />
                                </div>
                                <div className="luna__first__content__panels__right__row">
                                    <div className="luna__first__content__panels__right__row__number">05</div>
                                    <div className="luna__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Kvalitné plastové okná <b>s izolačným trojsklom</b>, sieťky proti hmyzu a interiérové žalúzie.') }} />
                                </div>
                                <div className="luna__first__content__panels__right__row">
                                    <div className="luna__first__content__panels__right__row__number">06</div>
                                    <div className="luna__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('Vnútorné povrchy v kvalite Q3 a vymaľované. Kúpeľňa obklad a dlažba. <b>Fásada vo farbe podľa vášho výberu.</b>') }} />
                                </div>
                                <div className="luna__first__content__panels__right__row">
                                    <div className="luna__first__content__panels__right__row__number">07</div>
                                    <div className="luna__first__content__panels__right__row__text" dangerouslySetInnerHTML={{ __html: __('<b>Kompletná hydroizolácia podlahy</b> formou EPDM fólie.') }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="luna__first__plan">
                        <ButtonBase onClick={() => this.setState({ photo: 10 })}>
                            <img src={IMAGES[`luna-plan.webp`]} />
                        </ButtonBase>
                    </div>
                    <div className="luna__first__photos">
                        {_.map([1,2,3,4,5,6,7,8,9,10], id => {
                            return (
                                <ButtonBase onClick={() => this.setState({ photo: toNumber(id) })} className="luna__first__photos__photo" key={id}>
                                    <img src={IMAGES[`luna-photo-${id}-mini.webp`]} />
                                </ButtonBase>
                            );
                        })}
                    </div>
                </div>
                <div className="luna__second">
                    <div className="luna__second__content">
                        <div className="luna__second__content__section">{__('Proces dodania')}</div>
                        <div className="luna__second__content__title">{__('Ako to bude prebiehať?')}</div>
                        <div className="luna__second__content__text" dangerouslySetInnerHTML={{ __html: __('Zhrnuli sme celý proces dodania do 5 krokov ktoré sme podrobnejšie opísali. Výstavba a splátkový kalendár prebieha v 4 etapách.') }} />
                        <div className="luna__second__content__panels">
                            <div className="luna__second__content__panels__panel">
                                <div className="luna__second__content__panels__panel__left">
                                    <div className="luna__second__content__panels__panel__left__title">{__('Prvá návšteva')}</div>
                                    <div className="luna__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Zavolajte alebo napíšte nám a dohodneme si stretnutie u nás kde uvidíte naživo aj vzorový dom <b>nášho menšieho modelu Elis 25m2.</b>') }} />
                                    <ButtonBase onClick={() => this.redirect('/kontakt')}>
                                        <span>{__('Kontakt na nás')}</span>
                                        <OpenInNewIcon />
                                    </ButtonBase>
                                </div>
                                <div className="luna__second__content__panels__panel__photo">
                                    <img src={IMAGES['public-1.webp']} />
                                </div>
                            </div>
                            <div className="luna__second__content__panels__panel">
                                <div className="luna__second__content__panels__panel__left">
                                    <div className="luna__second__content__panels__panel__left__title">{__('1. etapa (zmluva, vypracovanie projektu, stavebný úrad)')}</div>
                                    <div className="luna__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Ak sa rozhodnete pre výstavbu vášho domu u nás, tak obratom <b>pripravíme zmluvu</b> kde budete mať uvedený zoznam etáp a splátkový kalendár spolu s dodacími lehotami. Zmluvu vám vieme poslať emailom, poštou alebo ju môžete prísť podpísať osobne. Po podpise zmluvy budete uhrádzať prvú splátku <b>vo výške 5000 €.</b> Po úhrade náš projektant začne s vypracovaním projektu domu priamo na váš pozemok. Projekt by mal byť hotový približne <b>do 30 dní.</b> Keď náš projektant dokončí váš projekt, tak <b>pripravíme pre vás podklady</b> pre stavebné povolenie. Tieto podklady vám pošleme poštou alebo si pre ne môžte prísť osobne. Následne ich bude potrebné z vašej strany <b>podať na váš stavebný úrad.</b> Tento proces môže trvať až 90 dní.') }} />
                                    <ButtonBase onClick={() => this.redirect('/blog/stavebne-povolenie-alebo-ohlaska')}>
                                        <span>{__('Kedy je potrebné stavebné povolenie a kedy ohlásenie drobnej stavby?')}</span>
                                        <OpenInNewIcon />
                                    </ButtonBase>
                                </div>
                                <div className="luna__second__content__panels__panel__photo">
                                    <img src={IMAGES['elis-2.webp']} />
                                </div>
                            </div>
                            <div className="luna__second__content__panels__panel">
                                <div className="luna__second__content__panels__panel__left">
                                    <div className="luna__second__content__panels__panel__left__title">{__('2. etapa (základy, hrubá stavba)')}</div>
                                    <div className="luna__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Po definitívnom schválení stavby stavebným úradom budete uhrádzať dalšiu splátku <b>vo výške 34% z celkovej sumy</b>. Táto etapa zahŕňa vybudovanie základov, výstavbu konštrukcie domu spolu oknami a vstupnými dverami. Spolu s konštrukciou domu bude dokončená fasáda a strecha domu.') }} />
                                </div>
                                <div className="luna__second__content__panels__panel__photo">
                                    <img src={IMAGES['elis-3.webp']} />
                                </div>
                            </div>
                            <div className="luna__second__content__panels__panel">
                                <div className="luna__second__content__panels__panel__left">
                                    <div className="luna__second__content__panels__panel__left__title">{__('3. etapa (holodom)')}</div>
                                    <div className="luna__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Po dokončení hrubej stavby budete uhrádzať dalšiu splátku <b>vo výške 33% z celkovej sumy</b>. V tejto etape sú zahrnuté vnútorné izolácie, rozvody (elektro, voda, kanalizácia, hromozvod), sadrokartóny, výmaľba, zariadenie kúpeľne a podlahy.') }} />
                                </div>
                                <div className="luna__second__content__panels__panel__photo">
                                    <img src={IMAGES['elis-5.webp']} />
                                </div>
                            </div>
                            <div className="luna__second__content__panels__panel">
                                <div className="luna__second__content__panels__panel__left">
                                    <div className="luna__second__content__panels__panel__left__title">{__('4. etapa (kompletácia)')}</div>
                                    <div className="luna__second__content__panels__panel__left__text" dangerouslySetInnerHTML={{ __html: __('Pred poslednou etapou budete uhrádzať poslednú splátku <b>vo výške 33% z celkovej sumy</b>. Táto etapa zahŕňa kompletné dokončenie domu (nábytok, kuchyňa, interiérové dvere).') }} />
                                </div>
                                <div className="luna__second__content__panels__panel__photo">
                                    <img src={IMAGES['elis-6.webp']} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="luna__third">
                    <div className="luna__third__content">
                        <div className="luna__third__content__left">
                            <div className="luna__third__content__left__section">{__('Kvalita')}</div>
                            <div className="luna__third__content__left__title">{__('Dostanete to najlepšie')}</div>
                            <div className="luna__third__content__left__text" dangerouslySetInnerHTML={{ __html: __('Nadštandartné použitie izolácii v konštrukcii. Kvalitné šrobované spoje. Dvojitá vzduchotesnosť obálky domu. Kompletná hydroizolácia podlahy. Sadrokartónové povrchy celoplošne tmelené v kvalite Q3. Kvalitný nábytok namieru.') }} />
                        </div>
                        <div className="luna__third__content__right">
                            <div className="luna__third__content__right__item">
                                <div className="luna__third__content__right__item__name">{__('Izolácie, kategória A0')}</div>
                                <div className="luna__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['izolacie'].perex }} />
                                <ButtonBase onClick={() => this.redirect('/blog/izolacie')}>
                                    <span>{__('Prečítať viac')}</span>
                                    <OpenInNewIcon />
                                </ButtonBase>
                            </div>
                            <div className="luna__third__content__right__item">
                                <div className="luna__third__content__right__item__name">{__('Spoje a konštrukcia')}</div>
                                <div className="luna__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['spoje-a-konstrukcia'].perex }} />
                                <ButtonBase onClick={() => this.redirect('/blog/spoje-a-konstrukcia')}>
                                    <span>{__('Prečítať viac')}</span>
                                    <OpenInNewIcon />
                                </ButtonBase>
                            </div>
                            <div className="luna__third__content__right__item">
                                <div className="luna__third__content__right__item__name">{__('Dvojitá vzduchotesnosť')}</div>
                                <div className="luna__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['dvojita-vzduchotesnost'].perex }} />
                                <ButtonBase onClick={() => this.redirect('/blog/dvojita-vzduchotesnost')}>
                                    <span>{__('Prečítať viac')}</span>
                                    <OpenInNewIcon />
                                </ButtonBase>
                            </div>
                            <div className="luna__third__content__right__item">
                                <div className="luna__third__content__right__item__name">{__('Hydroizolácia podlahy')}</div>
                                <div className="luna__third__content__right__item__text" dangerouslySetInnerHTML={{ __html: BLOG_ARTICLES['hydroizolacia-podlahy'].perex }} />
                                <ButtonBase onClick={() => this.redirect('/blog/hydroizolacia-podlahy')}>
                                    <span>{__('Prečítať viac')}</span>
                                    <OpenInNewIcon />
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="luna__down">
                    <div className="luna__down__content">
                        <div className="luna__down__content__left">
                            <div className="luna__down__content__left__title">{__('Transparentnosť')}</div>
                            <div className="luna__down__content__left__text" dangerouslySetInnerHTML={{ __html: __('Dáme vám k dispozícii fotografie počas výstavby domu, stavebný denník prác a <b>faktúry nakúpeného tovaru</b>.') }} />
                            <ButtonBase onClick={() => this.redirect('/transparentnost')}>{__('Zobraziť viac')}</ButtonBase>
                        </div>
                        <div className="luna__down__content__photo">
                            <img src={IMAGES['transparentnost.webp']} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { fetchData, fetchSettings })(LunaScreen));
