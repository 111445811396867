import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Navigate } from '../components';
import {__, formatPhone} from '../functions';
import { changeSetting } from '../actions';
import '../assets/styles/menu.css';
import {ButtonBase} from "@mui/material";
import { PHONE, EMAIL } from '../config';
import ReactCountryFlag from "react-country-flag";

class Menu extends Navigate {

    render() {
        const { data } = this.props;

        if (_.isEmpty(data)) {
            // Nie su nacitane data
            return null;
        }

        const path = this.getPath();

        return (
            <div className="menu">
                <div className="menu__content">
                    <div className="menu__content__header">
                        <div className="menu__content__header__left" dangerouslySetInnerHTML={{ __html: __('<b>Kvalitné celoročné</b> domy do 50m2') }} />
                        <div className="menu__content__header__right">
                            <div className="menu__content__header__right__buttons">
                                <ButtonBase onClick={() => this.redirect('/kontakt')} className={path === '/kontakt' ? 'active' : ''}>{__('Kontakt')}</ButtonBase>
                                <ButtonBase onClick={() => window.location = `tel:${PHONE}`}>{formatPhone(PHONE)}</ButtonBase>
                                <ButtonBase onClick={() => window.location = `mailto:${EMAIL}`}>{EMAIL}</ButtonBase>
                            </div>
                        </div>
                    </div>
                    <div className="menu__content__data">
                        <ButtonBase onClick={() => window.location = '/'} className="menu__content__data__logo">
                            2by4
                        </ButtonBase>
                        <div className="menu__content__data__buttons">
                            <ButtonBase onClick={() => this.redirect('/elis')} className={path === '/elis' || path === '/' ? 'active' : ''}>{__('Elis 25m2')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/vera')} className={path === '/vera' ? 'active' : ''}>{__('Vera 42m2')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/zoja')} className={path === '/zoja' ? 'active' : ''}>{__('Zoja 48m2')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/transparentnost')} className={path === '/transparentnost' ? 'active' : ''}>{__('Transparentnosť')}</ButtonBase>
                            <ButtonBase onClick={() => this.redirect('/nasi-dodavatelia')} className={path === '/nasi-dodavatelia' ? 'active' : ''}>{__('Dodávatelia')}</ButtonBase>
                        </div>
                        <div className="menu__content__data__right">
                            <ReactCountryFlag countryCode="SK" svg />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const stateToProps = ({ data, settings }) => ({ data, settings });

export default withCookies(connect(stateToProps, { changeSetting })(Menu));
